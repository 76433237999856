import * as React from "react"

import Layout from "../components/Layout/LayoutBlank"
import Seo from "../components/Layout/Seo"
import { useCallback, useEffect, useState } from "react"
import {decode as base64_decode} from 'base-64';

export default function Pdf(){

    const [urlPdf, setUrlPdf] = useState("");

    const obtenerPdf = useCallback(() => {
      let urlPageSearch = "";
      let params = "";
      let urlDecode = "";
      try {
        urlPageSearch = window.location.search;
        params = new URLSearchParams(urlPageSearch);
        if(params && params.get("url")) urlDecode = base64_decode(params.get("url"));
        var pattern = new RegExp('^(https?:\\/\\/)?'+
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
          '((\\d{1,3}\\.){3}\\d{1,3}))'+
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
          '(\\?[;&a-z\\d%_.~+=-]*)?'+
          '(\\#[-a-z\\d_]*)?$','i');
        console.log("validando");
        if(pattern.test(urlDecode)){
          params.get("url") ? setUrlPdf(urlDecode) : setUrlPdf("");
          console.log("pdf",urlDecode);
        }
      } catch {console.log("archivo no encontrado");}
    }, []);
  
    useEffect(() => {
      obtenerPdf();
    }, [obtenerPdf])

    return(
        <Layout>
        <Seo title="Pdf" />
        {
            urlPdf &&
            <iframe className="only-desk iframe-pdf" title="pdf" src={urlPdf} frameborder="0" allowfullscreen/>
        }
        { urlPdf &&
            <iframe className="only-mov iframe-pdf" title="pdf" src={`https://www.labok.com/santa-isabel-169/pdf-embed/?file=${urlPdf}`} frameBorder="0" allowFullScreen />
        }
      </Layout>
    )
}